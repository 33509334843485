import React from "react"

import image from "../images/twitter-large.png";

const Seo = ({children, data}) => {
  return (
    <>
      <title id="title">{data.site.siteMetadata.title}</title>
      <meta name="twitter:description" content={data.site.siteMetadata.description} />
      <meta name="twitter:title" content={data.site.siteMetadata.title} />
      <meta name="twitter:site" content={data.site.siteMetadata.twitter} />
      <meta name="twitter:card" content="summary_large_image"/>
      <meta property="og:image" content={data.site.siteMetadata.siteUrl + image} />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content={data.site.siteMetadata.description} />
      <meta property="og:title" content={data.site.siteMetadata.title} />
      <meta name="description" content={data.site.siteMetadata.description} />
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://noop.co.jp",
          "name": "${data.site.siteMetadata.title}",
          "sameAs": ["https://twitter.com/noop_llc"]
        }
      `}</script>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "@id": "https://noop.co.jp/#organization",
          "url": "https://noop.co.jp",
          "name": "合同会社Noop",
          "logo": {
            "@type": "ImageObject",
            "@id": "https://noop.co.jp/#logo",
            "url": "https://noop.co.jp/icon.png"
          },
          "sameAs": ["https://twitter.com/noop_llc"],
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "telephone": "+81-050-3551-2956",
            "email": "info@noop.co.jp"
          },
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "JP",
            "addressRegion": "Toyama"
          }
        }
      `}</script>
      {children}
    </>
  );
};

export default Seo;